<template>
  <div class="mt-n6 pt-3 background">
    <div class="mt-6 d-flex justify-center">
    <v-btn  @click="$router.push('/gallerie')"  class="galerie"> zur Gallerie </v-btn>

    </div>

    <v-card class=" ma-6" dark>
      <v-card-title >Impressum</v-card-title>

      <v-card-text>
        <v-card-title >Angaben gemäß § 5 TMG:</v-card-title>
        Privatperson <br>
        Jochen Franke & Marina Franke <br>
        St.Wendelerstraße 77 <br>
        66640 Namborn <br>
        <v-card-title >Kontakt:</v-card-title>

        Telefon: +49 170 5574750 <br>
        E-Mail: franke.arts@gmail.com<br>

        <v-card-title >Verantwortlicher i.S.d. § 18 Abs. 2 MStV:</v-card-title>


        Jochen Franke & Marina Franke <br>
        St.Wendelerstraße 77 <br>
        66640 Namborn <br>

        <v-card-title >Urheberrechtliche Hinweise:</v-card-title>

        Das Urheberrecht für die Website liegt bei Stefan Franke.
      </v-card-text>
    </v-card>
    <v-card class="ma-6" dark>
      <v-card-title>Datenschutz</v-card-title>
      <v-card-text>Die Nutzung dieser Website kann mit der Verarbeitung von personenbezogenen Daten verbunden sein. Damit diese Verarbeitungen für Sie nachvollziehbar sind,
        <br> möchten wir Ihnen mit den folgenden Informationen einen Überblick zu diesen Verarbeitungen verschaffen.
        <br> Um eine faire Verarbeitung zu gewährleisten, möchten wir Sie außerdem über Ihre Rechte nach der
        <br>Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG) informieren.</v-card-text>
      <br>
      <br>
      <v-card-text>
        Wenn Sie Fragen oder Anregungen zu diesen Informationen haben oder sich wegen der <br>
        Geltendmachung Ihrer Rechte an uns wenden möchten, richten Sie Ihre Anfrage bitte an <br> <br>

        <br>
        Adresse: <br>
        Stefan Franke <br>
        Weiße Taube. 12 <br>
        44229 Dortmund <br>
        E-Mail-Adresse: stefanfausst@web.de<br>
        Telefonnummer: +491705574750 <br> <br>

        Unser Datenschutzbeauftragter ist unter folgenden Kontaktdaten zu erreichen: <br> <br>

        stefanfausst@web.de</v-card-text>
      <br>
      <br>
      <v-card-title>Allgemeine Angaben zur Datenverarbeitung, Rechtsgrundlagen und Speicherdauer</v-card-title>
      <br>
      <v-card-subtitle>Allgemeine Angaben zur Datenverarbeitung</v-card-subtitle>
      <v-card-text>
        Wir verarbeiten personenbezogene Daten unter Beachtung der einschlägigen Datenschutzvorschriften, insbesondere der DSGVO und des BDSG. Eine Datenverarbeitung durch uns findet nur
        <br> auf der Grundlage einer gesetzlichen Erlaubnis statt. Bei der Nutzung dieser Website verarbeiten wir personenbezogene Daten nur mit Ihrer Einwilligung
        <br> (Art. 6 Abs. 1 Buchst. a) DSGVO), zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, oder auf Ihre Anfrage zur Durchführung vorvertraglicher Maßnahmen
        <br> (Art. 6 Abs. 1 Buchst. b) DSGVO), zur Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 Buchst. c) DSGVO) oder wenn die Verarbeitung zu Wahrung unser berechtigten
        <br> Interessen oder den berechtigten Interessen eines Dritten erforderlich ist, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern,
        <br>überwiegen (Art. 6 Abs. 1 Buchst. f) DSGVO).
      </v-card-text>
      <br>
      <v-card-subtitle>Dauer der Speicherung</v-card-subtitle>

      <v-card-text>
        Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, speichern wir die Daten nur solange, wie es zur Erreichung des
        <br> Verarbeitungszwecks oder für die Erfüllung unserer vertraglichen oder gesetzlichen Pflichten erforderlich ist. Solche gesetzlichen
        <br> Aufbewahrungspflichten können sich insbesondere aus handels- oder steuerrechtlichen Vorschriften ergeben.
      </v-card-text>
      <br>
      <v-card-title>Automatisierte Datenverarbeitung des Servers</v-card-title>
      <br>
      <v-card-subtitle>Verarbeitung von Server-Log-Files</v-card-subtitle>
      <v-card-text>
        Bei der rein informativen Nutzung unserer Website werden zunächst automatisiert (also nicht über eine Registrierung) allgemeine Informationen gespeichert,<br>
        die Ihr Browser an unseren Server übermittelt. Hierzu zählen standardmäßig: Browsertyp/ -version, verwendetes Betriebssystem, aufgerufene Seite, die zuvor besuchte
        <br>Seite (Referrer URL), IP-Adresse, Datum und Uhrzeit der Serveranfrage und HTTP-Statuscode. Die Verarbeitung erfolgt zur Wahrung unserer berechtigten Interessen
        <br> und beruht auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO. Diese Verarbeitung dient der technischen Verwaltung und der Sicherheit der Website.
        <br> Die gespeicherten Daten werden gelöscht, wenn nicht aufgrund konkreter Anhaltspunkte ein berechtigter Verdacht auf eine rechtswidrige Nutzung besteht und
        <br> eine weitere Prüfung und Verarbeitung der Informationen aus diesem Grund erforderlich ist.
      </v-card-text>
      <br>
      <v-card-title>Datenverarbeitung durch Nutzerangaben</v-card-title>
      <br>
      <v-card-text>Informationserhebungen durch Kontaktformulare, Registrierungen, Bewerbungen, Anmeldungen zu Newslettern, Bestellung von Waren, Bezahlsysteme etc.</v-card-text>
      <br>
      <v-card-title>Cookies, Tracking, Plugins, externe Inhalte</v-card-title>
      <br>
      <v-card-subtitle>Cookies</v-card-subtitle>
      <v-card-text>Wir verwenden auf unserer Website Cookies. Bei Cookies handelt es sich um kleine Textdateien, die durch Ihren Browser gespeichert werden, wenn Sie eine Website besuchen. Hierdurch wird der verwendete Browser gekennzeichnet und kann durch unseren Webserver wiedererkannt werden. Dabei verwenden wir insbesondere dauerhafte Cookies („Persistent Cookies“) für die Analyse unserer Website. Diese Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sofern es durch diese Verwendung von Cookies zu einer Verarbeitung personenbezogener Daten kommt, beruht diese auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO. Diese Verarbeitung dient unserem berechtigten Interesse, unsere Website nutzerfreundlicher, effektiver sowie sicherer zu machen. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen. Sie können der Verwendung von Cookies durch Ihre Browsereinstellungen grundsätzlich widersprechen.</v-card-text>
      <v-card-subtitle>Social Plugins</v-card-subtitle>
      <v-card-text>Wir verwenden auf unserer seite verlinkungen von anderen Social Media Plattformen. Dabei handelt es sich um Videos die auf unserer Website Angezeigt werden. Die rechtliche Grundlagen bleiben jedoch bei der Ursprungswesite. </v-card-text>
      <br>
      <v-card-title>Rechte der Betroffenen, Beschwerderecht</v-card-title>
      <br>
      <v-card-subtitle>Ihre Rechte</v-card-subtitle>
      <v-card-text>Als betroffene Person haben Sie das Recht, uns gegenüber Ihre Betroffenenrechte geltend zu machen. Dabei haben Sie insbesondere die folgenden Rechte:</v-card-text>
      <v-list>
        <v-list-item> - Sie haben nach Maßgabe des Art.15 DSGVO und § 34 BDSG das Recht, Auskunft darüber zu verlangen, ob und gegebenenfalls in welchen Umfang wir personenbezogene Daten zu Ihrer Person verarbeiten oder nicht.</v-list-item>
        <v-list-item> - Sie haben das Recht, nach Maßgabe des Art. 16 DSGVO von uns die Berichtigung Ihrer Daten zu verlangen.</v-list-item>
        <v-list-item> - Sie haben das Recht, nach Maßgabe des Art. 17 DSGVO und § 35 BDSG von uns die Löschung Ihrer personenbezogenen Daten zu verlangen.</v-list-item>
        <v-list-item> - Sie haben das Recht, nach Maßgabe des Art. 18 DSGVO die Verarbeitung Ihrer personenbezogenen Daten einschränken zu lassen.</v-list-item>
        <v-list-item> - Sie haben das Recht, nach Maßgabe des Art. 20 DSGVO die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und diese Daten einem anderen Verantwortlichen zu übermitteln.</v-list-item>
        <br>
        <v-list-item> - Sie haben nach Maßgabe des Art. 21 Abs. 1 DSGVO das Recht, gegen jede Verarbeitung, die auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. e) oder f) DSGVO beruht, Widerspruch einzulegen. Sofern durch uns personenbezogene Daten über Sie zum Zweck der Direktwerbung verarbeitet werden, können Sie gegen diese Verarbeitung gem. Art. 21 Abs. 2 und Abs. 3 DSGVO Widerspruch einlegen.</v-list-item>
      </v-list>
      <v-card-text>Sofern Sie uns eine gesonderte Einwilligung in die Datenverarbeitung erteilt haben, können Sie diese Einwilligung nach Maßgabe des Art. 7 Abs. 3 DSGVO jederzeit widerrufen. Durch einen solchen Widerruf wird die Rechtmäßigkeit der Verarbeitung, die bis zum Widerruf aufgrund der Einwilligung erfolgt ist, nicht berührt.</v-card-text>
      <br>
      <v-card-subtitle>Beschwerde bei einer Aufsichtsbehörde</v-card-subtitle>
      <v-card-text>Wenn Sie der Ansicht sind, dass eine Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Bestimmungen der DSGVO verstößt, haben Sie nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde.</v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Impressum"
}
</script>

<style scoped>
.background{
  background-image: url("../assets/hintergrundJ.jpeg");
  background-attachment: fixed;

}
.galerie{
  color: white;
  background: #E67E22 !important;

}
</style>
