import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jochenAnzeige: true,
    marinaAnzeige: false,
    head:"Jochen's Bilder",
    bilderJochen:[{
      bild: require('../assets/J1.png'),
      beschreibung:'Acryl auf Leinwand',preis: '400',
      status: 'Vorhanden',
      name:'Finsternis',
      nummer:'100',
      größe:'40x50',
    },
      {
        bild: require('../assets/J2.png'),
        beschreibung:'Acryl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Cosmos',
        nummer:'101',
        größe:'40x50',
      },
      {
        bild: require('../assets/J3.png'),
        beschreibung:'Acryl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Atom',
        nummer:'102',
        größe:'40x50',

      },
      {
        bild: require('../assets/J4.png'),
        beschreibung:'Acryl auf Leinwand',preis: '550',
        status: 'vorhanden',
        name:'Fluid',
        nummer:'103',
        größe:'40x50',

      },
      {
        bild: require('../assets/J5.png'),
        beschreibung:'Acryl auf Leinwand',preis: '350',
        status: 'Vorhanden',
        name:'Underground',
        nummer:'104',
        größe:'30x40',

      },
      {
        bild: require('../assets/J6.png'),
        beschreibung:'Acryl auf Leinwand',preis: '450',
        status: 'Vorhanden',
        name:'Invasion',
        nummer:'105',
        größe:'40x50',

      },

      {
        bild: require('../assets/J8.png'),
        beschreibung:'Acryl auf Leinwand',preis: '150',
        status: 'Vorhanden',
        name:'Lost Place',
        nummer:'106',
        größe:'18x24',

      },
      {
        bild: require('../assets/J9.png'),
        beschreibung:'Acryl auf Leinwand',preis: '150',
        status: 'Vorhanden',
        name:'Think',
        nummer:'107',
        größe:'18x24',

      },
      {
        bild: require('../assets/J10.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Comic Arts',
        nummer:'108',
        größe:'50x50',

      },
      {
        bild: require('../assets/J11.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Iceland',
        nummer:'109',
        größe:'70x30',

      },
      {
        bild: require('../assets/J12.png'),
        beschreibung:'Acryl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Dom',
        nummer:'110',
        größe:'70x30',

      },
      {
        bild: require('../assets/J13.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Comic Arts 2',
        nummer:'111',
        größe:'50x70',

      },
      {
        bild: require('../assets/J14.png'),
        beschreibung:'Acryl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Heart',
        nummer:'112',
        größe:'22x16',

      },
      {
        bild: require('../assets/J15.png'),
        beschreibung:'Acryl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Heart 2',
        nummer:'113',
        größe:'22x16',

      },
      {
        bild: require('../assets/J16.png'),
        beschreibung:'Acryl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Loki',
        nummer:'114',
        größe:'60x30',

      },
      {
        bild: require('../assets/J17.png'),
        beschreibung:'Acryl auf Leinwand',preis: '900',
        status: 'Vorhanden',
        name:'Future view',
        nummer:'115',
        größe:'74x57',

      },
      {
        bild: require('../assets/J18.png'),
        beschreibung:'Acryl auf Leinwand',preis: '800',
        status: 'Vorhanden',
        name:'Merge',
        nummer:'116',
        größe:'50x40',

      },
      {
        bild: require('../assets/J19.png'),
        beschreibung:'Acryl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Sky Island',
        nummer:'117',
        größe:'30x30',

      },
      {
        bild: require('../assets/J20.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Wormhole',
        nummer:'118',
        größe:'40x40',

      },
      {
        bild: require('../assets/J21.png'),
        beschreibung:'Acryl auf Leinwand',preis: '1100',
        status: 'Vorhanden',
        name:'Destination',
        nummer:'119',
        größe:'40x120',

      },
      {
        bild: require('../assets/J22.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Starlight',
        nummer:'120',
        größe:'60x80',

      },
      {
        bild: require('../assets/J23.png'),
        beschreibung:'Acryl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Ice Barrier',
        nummer:'121',
        größe:'38x28',

      },
      {
        bild: require('../assets/J24.png'),
        beschreibung:'Acryl auf Leinwand',preis: '1300',
        status: 'Vorhanden',
        name:'Bullseye',
        nummer:'123',
        größe:'100x120',

      },
      {
        bild: require('../assets/J25.png'),
        beschreibung:'Acryl auf Leinwand',preis: '900',
        status: 'Vorhanden',
        name:'Bifröst',
        nummer:'124',
        größe:'90x30',

      },
      {
        bild: require('../assets/J26.png'),
        beschreibung:'Acryl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Ying & Yang',
        nummer:'125',
        größe:'40x40',

      },
      {
        bild: require('../assets/J27.png'),
        beschreibung:'Acryl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Incubation',
        nummer:'126',
        größe:'90x30',

      },
      {
        bild: require('../assets/J28.png'),
        beschreibung:'Acryl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Jahreszeiten',
        nummer:'127',
        größe:'30x30',

      },
      {
        bild: require('../assets/J29.png'),
        beschreibung:'Acryl auf Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Utopia',
        nummer:'128',
        größe:'50x70',

      },
      {
        bild: require('../assets/J30.png'),
        beschreibung:'Acryl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Galaxy',
        nummer:'129',
        größe:'30x30',

      },
      {
        bild: require('../assets/31.png'),
        beschreibung:'Acryl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Tschernobyl',
        nummer:'130',
        größe:'40x40',

      },
      {
        bild: require('../assets/32.png'),
        beschreibung:'Acryl auf Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Core',
        nummer:'131',
        größe:'30x30',

      }],



    //-----------------------------------------------------------------------------------------
    bilderMarina:[{
      bild: require('../assets/M1.png'),
      beschreibung:'Acryl Pouring Leinwand',
      preis: '300',
      status: 'Vorhanden',
      name:'Pfau',
      nummer:'132',
      größe:'40x50',

    },
      {
        bild: require('../assets/M2.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '120',
        status: 'Vorhanden',
        name:'Lagune',
        nummer:'133',
        größe:'30x20',
      },
      {
        bild: require('../assets/M3.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '250',
        status: 'Vorhanden',
        name:'Ströhmung',
        nummer:'134',
        größe:'30x30',
      },
      {
        bild: require('../assets/M5.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Bodenlos',
        nummer:'135',
        größe:'30x30',
      },
      {
        bild: require('../assets/M6.png'),
        beschreibung:'Blattmetall Öl auf Leinwand',preis: '900 ',
        status: 'Vorhanden',
        name:'Meditation ',
        nummer:'136',
        größe:'30x40 50x70 30x40',
      },
      {
        bild: require('../assets/M7.png'),
        beschreibung:'Acryl Pouring Leinwand',
        preis: '320',
        status: 'Vorhanden',
        name:'Butterflyeffect ',
        nummer:'137',
        größe:'40x40',
      },
      {
        bild: require('../assets/M8.png'),
        beschreibung:'Öl auf Leinwand',
        preis: '900',
        status: 'Vorhanden',
        name:'Butterfly 1',
        nummer:'138',
        größe:'70x100',
      },
      {
        bild: require('../assets/M9.png'),
        beschreibung:'Öl auf Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Butterfly 2',
        nummer:'139',
        größe:'40x50',
      },
      {
        bild: require('../assets/M10.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Twistet Pair',
        nummer:'140',
        größe:'30x60',
      },
      {
        bild: require('../assets/M11.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Bubbles',
        nummer:'141',
        größe:'30x80',
      },
      {
        bild: require('../assets/M12.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Twistet Pair 2',
        nummer:'142',
        größe:'40x30',
      },
      {
        bild: require('../assets/M13.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Unterwasser Welten',
        nummer:'143',
        größe:'58x58',
      },
      {
        bild: require('../assets/M14.png'),
        beschreibung:'Öl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Ebbe',
        nummer:'144',
        größe:'30x70',
      },
      {
        bild: require('../assets/M16.png'),
        beschreibung:'Öl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Coffee Time',
        nummer:'146',
      größe:'20x50 22x32',
      },
      {
        bild: require('../assets/M17.png'),
        beschreibung:'Öl auf Leinwand',preis: '130',
        status: 'Vorhanden',
        name:'Caffe Latte 1',
        nummer:'147',
      größe:'30x30',
      },
      {
        bild: require('../assets/M18.png'),
        beschreibung:'Öl auf Leinwand',preis: '130',
        status: 'Vorhanden',
        name:'Moca',
        nummer:'148',
        größe:'30x30',

      },
      {
        bild: require('../assets/M19.png'),
        beschreibung:'Öl auf Leinwand',preis: '330',
        status: 'Vorhanden',
        name:'Regencalla',
        nummer:'149',
        größe:'40x30',
      },
      {
        bild: require('../assets/M20.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '150',
        status: 'Vorhanden',
        name:'Seezunge',
        nummer:'150',
      größe:'20x30',
      },
      {
        bild: require('../assets/M21.png'),
        beschreibung:"Acryl Pouring Leinwand",preis: '600',
        status: 'Vorhanden',
        name:'Amytist',
        nummer:'151',
      größe:'50x50',
      },
      {
        bild: require('../assets/M22.png'),
        beschreibung:'Öl auf Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Freesien',
        nummer:'152',
        größe:'50x70',
      },
      {
        bild: require('../assets/M23.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '1000',
        status: 'Vorhanden',
        name:'Floora',
        nummer:'153',
        größe:'28x38 80x80 34x44',
      },


      {
        bild: require('../assets/M26.png'),
        beschreibung:'Öl auf Holz',preis: '200',
        status: 'Vorhanden',
        name:'Alchemie',
        nummer:'156',
        größe:'41x41',
      },
      {
        bild: require('../assets/M27.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '250',
        status: 'Vorhanden',
        name:'Wasser Phönix',
        nummer:'157',
        größe:'30x30',
      },
      {
        bild: require('../assets/M28.png'),
        beschreibung:'Öl auf Leinwand',preis: '700',
        status: 'Vorhanden',
        name:'Rote Calla',
        nummer:'158',
        größe:'80x54',
      },
      {
        bild: require('../assets/M29.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '700',
        status: 'Vorhanden',
        name:'Rote Flut',
        nummer:'159',
         größe:'70x50',
      },
      {
        bild: require('../assets/M30.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '500',
        status: 'Verkauft',
        name:'Albtraum',
        nummer:'160',
        größe:'60x60',
      },
      {
        bild: require('../assets/M31.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '600',
        status: 'Vorhanden',
        name:'Ebbe 2',
        nummer:'161',
        größe:'60x60',
      },
      {
        bild: require('../assets/M32.png'),
        beschreibung:'Öl auf Leinwand',preis: '800',
        status: 'Vorhanden',
        name:'Raum der Zeit',
        nummer:'162',
      größe:'80x60',
      },
      {
        bild: require('../assets/M33.png'),
        beschreibung:'Öl auf Leinwand',preis: '700',
        status: 'Vorhanden',
        name:'Raum der Zeit 2',
        nummer:'163',
        größe:'40x80',
      },
      {
        bild: require('../assets/M34.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Federkleid',
        nummer:'164',
      größe:'30x40',
      },
      {
        bild: require('../assets/M35.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '250',
        status: 'Vorhanden',
        name:'Bodenlos 2',
        nummer:'165',
      größe:'30x30',
      },
      {
        bild: require('../assets/M36.png'),
        beschreibung:'Acryl Leinwand',preis: '100',
        status: 'Vorhanden',
        name:'Floora 2',
        nummer:'166',
      größe:'50x40',
      },
      {
        bild: require('../assets/M37.png'),
        beschreibung:'Acryl Leinwand',preis: '100',
        status: 'Vorhanden',
        name:'Colibri',
        nummer:'167',
      größe:'40x30',
      },
      {
        bild: require('../assets/M38.png'),
        beschreibung:'Acryl Leinwand',preis: '80',
        status: 'Vorhanden',
        name:'Portrait Ramen',
        nummer:'168',
      größe:'40x40',
      },
      {
        bild: require('../assets/M39.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Algenblüten 1',
        nummer:'169',
      größe:'20x60',
      },
      {
        bild: require('../assets/M40.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Algenblüten 2',
        nummer:'170',
      größe:'20x60',
      },
      {
        bild: require('../assets/M41.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Algenblüten 3',
        nummer:'171',
      größe:'20x60',
      },


      {
        bild: require('../assets/M45.png'),
        beschreibung:'Öl auf Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Calla 1',
        nummer:'173',
        größe:'30x60',
      },
      {
        bild: require('../assets/M44.png'),
        beschreibung:'Öl auf Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Calla 2',
        nummer:'174',
        größe:'30x70',
      },
      {
        bild: require('../assets/M46.png'),
        beschreibung:'Öl auf Leinwand',preis: '300',
        status: 'Vorhanden',
        name:'Calla 3',
        nummer:'175',
      größe:'30x60',
      },
      {
        bild: require('../assets/M42.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Dragon Egg',
        nummer:'172',
        größe:'20x50',
      },
      {
        bild: require('../assets/M43.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Algenblüten 4',
        nummer:'176',
      größe:'20x60',
      },
      {
        bild: require('../assets/M47.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '250',
        status: 'Vorhanden',
        name:'Einmündung',
        nummer:'177',
      größe:'20x50',
      },
      {
        bild: require('../assets/M48.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '250',
        status: 'Vorhanden',
        name:'Wellen',
        nummer:'178',
      größe:'20x50',
      },
      {
        bild: require('../assets/M49.png'),
        beschreibung:'Acryl Pouring Leinwand',
        preis: '250',
        status: 'Vorhanden',
        name:'Inspiration',
        nummer:'179',
      größe:'20x50',
      },
      {
        bild: require('../assets/M50.png'),
        beschreibung:'Acryl Pouring Leinwand',
        preis: '260',
        status: 'Vorhanden',
        name:'Bazillus',
        nummer:'180',
      größe:'20x50',
      },
      {
        bild: require('../assets/M51.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '400',
        status: 'Vorhanden',
        name:'Senneneruption',
        nummer:'181',
        größe:'30x70',
      },
      {
        bild: require('../assets/M52.png'),
        beschreibung:'Acryl auf Leinwand',preis: '200',
        status: 'Vorhanden',
        name:'Marmor',
        nummer:'182',
        größe:'30x40',
      },
      {
        bild: require('../assets/M53.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '150',
        status: 'Vorhanden',
        name:'Inspiration 2',
        nummer:'183',
        größe:'30x30',
      },
      {
        bild: require('../assets/M54.png'),
        beschreibung:'Acryl Pouring Leinwand',preis: '500',
        status: 'Vorhanden',
        name:'Scalare',
        nummer:'184',
        größe:'50x50',
      },
      {
        bild: require('../assets/M55.png'),
        beschreibung:'Öl auf Leinwand',preis: '700',
        status: 'Vorhanden',
        name:'Sakura',
        nummer:'185',
        größe:'40x50 30x40',
      }
      ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
